
import moment from "moment-timezone";
import { defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import {
  getLogCrawlerDetail,
  ILogCrawlerDetail,
  LogCrawlerDetail,
} from "./logCrawler";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useRoute } from "vue-router";
import { getProjectList } from "@/core/helpers/generalHelper";

export default defineComponent({
  name: "LogCrawlerDetail",
  components: {
    Datatable,
  },
  setup() {
    const tableData = ref<Array<ILogCrawlerDetail>>(LogCrawlerDetail);
    const tableKey = ref(0);
    const dataProject = ref([]);
    const total_data = ref(0);
    const is_loading = ref(true);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const TypeValue = ref("");
    const route = useRoute();
    const sourceValue = ref<string>("");
    const modelSelect = ref({
      name: "",
      id: "",
    });
    const dataSource = ref([
      {
        value: "media_tv",
        label: "Media TV",
      },
      {
        value: "media_cetak",
        label: "Media Cetak",
      },
      {
        value: "media_online",
        label: "Media Online",
      },
    ]);
    const code = ref(route.params.name);
    const tableHeaderCrawler = ref([
      {
        name: "Project Name",
        key: "project",
        sortable: true,
      },
      {
        name: "Keyword",
        key: "keyword",
        sortable: true,
      },
      {
        name: "Topic",
        key: "topic",
        sortable: true,
      },
      {
        name: "Source",
        key: "source",
      },
      {
        name: "Type",
        key: "type",
      },
      {
        name: "Tanggal",
        key: "tanggal",
      },
      {
        name: "From",
        key: "from",
      },
      {
        name: "To",
        key: "to",
      },
      {
        name: "Count",
        key: "count",
      },
    ]);
    const fetchData = async () => {
      try {
        const payload = {
          source: code.value,
          page: currentPage.value,
          item: itemsPerPage.value,
        };

        if (modelSelect.value.name != "") {
          payload["id_project"] = modelSelect.value.id;
        }

        if (TypeValue.value != "") {
          payload["tipe"] = TypeValue.value;
        }
        is_loading.value = true;
        const res = await getLogCrawlerDetail(payload);
        tableData.value = res.data;
        total_data.value = res.total;
        is_loading.value = false;
        tableKey.value += 1;
      } catch (error) {
        tableData.value = [];
      }
    };

    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchData();
    };

    const listProejct = async () => {
      const res = await getProjectList();
      dataProject.value = res;
    };

    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchData();
    };

    const filterProjectChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        modelSelect.value.name = value.name;
        modelSelect.value.id = value._id;
      } else {
        modelSelect.value.name = "";
        modelSelect.value.id = "";
      }
      fetchData();
    };

    const filterTypeChange = async (value: any) => {
      currentPage.value = 1;
      if (value) {
        TypeValue.value = value;
      } else {
        TypeValue.value = "";
      }
      fetchData();
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Crawler Detail", ["Log"]);
      fetchData();
      listProejct();

      MenuComponent.reinitialization();
    });

    return {
      tableHeaderCrawler,
      total_data,
      is_loading,
      currentPage,
      itemsPerPage,
      fetchData,
      tableData,
      tableKey,
      currentChange,
      itemsPerPageChange,
      listProejct,
      dataProject,
      filterProjectChange,
      modelSelect,
      dataSource,
      sourceValue,
      TypeValue,
      filterTypeChange,
      moment,
    };
  },
});
